import React from 'react';
import { useEffect, useState } from 'react';
import {
  Layout,
  Main,
  Button,
  Box,
  BoxContainer,
  BoxTitle,
  SliderContainer,
  SliderSpace,
  SliderBg,
  LogoContainer,
  Title,
} from './clean-landing.style';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Stars from './stars.png';
import TiktokPixel from 'tiktok-pixel';
import { tiktokParms } from '../../utils/tiktokParms';
import { metaParms } from '../../utils/metaParms';
import { metaAdjustLink } from '../../utils/metaAdjustLink';
import { tiktokAdjustLink } from '../../utils/tiktokAdjustLink';
import * as amplitude from '@amplitude/analytics-browser';

const CleanLanding = ({ content }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    afterChange: (current) => current === 3 && redirectStore(appURL),
  };

  const redirectStore = (appURL) => {
    const event = {
      event_type: 'clicked_store_button',
    };
    amplitude.track(event);

    window.location.href = appURL;
  };

  const [appURL, setAppURL] = useState('');

  useEffect(() => {
    import('react-facebook-pixel')
      .then((module) => module.default)
      .then((ReactPixel) => {
        //Facebook Tracking
        ReactPixel.init(process.env.GATSBY_PIXEL_META_ID);
        ReactPixel.pageView();
        ReactPixel.track('ViewContent');

        //Tiktok Tracking
        TiktokPixel.init(process.env.GATSBY_PIXEL_TIKTOK_ID);
        TiktokPixel.pageView();

        //Amplitude tracking

        amplitude.init(process.env.GATSBY_AMPLITUDE_ID, {
          serverZone: 'EU',
          defaultTracking: false,
        });
      });
  }, [content.semSupplier]);

  useEffect(() => {
    let adjust = '';
    if (content.semSupplier === 'TT') {
      adjust = tiktokAdjustLink();
    } else if (content.semSupplier === 'MT') {
      adjust = metaAdjustLink();
    }
    setAppURL(adjust.adjustURL + adjust.params);
  }, []);

  const redirectHandler = () => {
    import('react-facebook-pixel')
      .then((module) => module.default)
      .then((ReactPixel) => {
        //Facebook Tracking
        ReactPixel.track('CompleteRegistration');
      });

    let userprops = {};

    if (content.semSupplier === 'TT') {
      userprops = tiktokParms();
    } else if (content.semSupplier === 'MT') {
      userprops = metaParms();
    }

    const identifyEvent = new amplitude.Identify();

    Object.keys(userprops).forEach((key) => {
      const value = userprops[key];
      identifyEvent.set(key, value);
    });

    amplitude.identify(identifyEvent);

    if (typeof window !== 'undefined') {
      redirectStore(appURL);
    }
  };

  const testimonials = [
    {
      title: content.testimonials.title1,
      description: content.testimonials.desciption1,
    },
    {
      title: content.testimonials.title2,
      description: content.testimonials.description2,
    },
    {
      title: content.testimonials.title3,
      description: content.testimonials.description3,
    },
    {
      title: content.testimonials.title4,
      description: content.testimonials.description4,
    },
  ];

  return (
    <Layout>
      <LogoContainer>
        <img
          src={`/assets/images/${content.project}/logo-top.svg`}
          onClick={redirectHandler}
          alt="logo"
        />
      </LogoContainer>
      <Title>
        <p dangerouslySetInnerHTML={{ __html: content.title }} />
      </Title>
      <Main>
        <h2 dangerouslySetInnerHTML={{ __html: content.bannerTitle }} />
        <p>{content.bannerDescription}</p>
      </Main>
      <SliderSpace>
        <SliderContainer>
          <SliderBg>
            <Slider {...settings}>
              {testimonials.map((item, i) => (
                <BoxContainer key={item.title}>
                  <Box
                    $mode={
                      content.testimonials.reduceTextSizeForBiggerLanguage ===
                        'Yes' && 'small'
                    }
                  >
                    <img src={Stars} alt="Stars" />
                    <BoxTitle
                      $mode={
                        content.testimonials.reduceTextSizeForBiggerLanguage ===
                          'Yes' && 'small'
                      }
                      className={'lang-' + content.language}
                    >
                      {item.title}
                    </BoxTitle>
                    <p
                      className={'lang-' + content.language}
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />
                  </Box>
                </BoxContainer>
              ))}
            </Slider>
          </SliderBg>
        </SliderContainer>
      </SliderSpace>
      <Button onClick={redirectHandler}>{content.ctaTxt}</Button>
    </Layout>
  );
};

export default CleanLanding;
